export default {
  GET(state, data) {
    state.brand = { ...data }
  },
  LIST(state, data) {
    state.brands = data
  },
  SET_FORM(state, data) {
    state.brandForm = { ...data }
  },
  SET_IN_THIS_BRAND_PRODUCTS_LIST(state, data) {
    state.inThisBrandProducts = [data]
  },
}
