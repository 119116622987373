import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'brands'
const Actions = crudActions(endpoint)
export default {
  ...Actions,
  getInThisBrandProducts: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get('products', { params: queryParams, paramsSerializer: params => stringify(params) })
      .then(response => {
        commit('SET_IN_THIS_BRAND_PRODUCTS_LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
